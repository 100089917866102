import { parseSomething } from "../utils/parseSomething";

export const colorConfig =
  process.env.REACT_APP_SECRET_TYPE === "local"
    ? {
        gold: {
          leaderBoard: {
            left: "#b6881b",
            right: "#d09b1e",
            border: "#9c7417",
            avatar: "#e4b546",
            "v3-border": "#C9D0E9",
          },
          brand: {
            100: "#f7e9c8",
            200: "#f3dfae",
            300: "#efd494",
            400: "#ecca7a",
            500: "#e4b546",
            600: "#e0ab2c",
            700: "#d09b1e",
            800: "#c3911c",
            900: "#b6881b",
            950: "#a97e19",
            1000: "#9c7417",
          },
          special: {
            1: "#6779B1",
            gold: "#f8e709",
            "jackpot-from": "#FEF87D",
            "jackpot-to": "#F6EB20",
          },
          "game-item": {
            mask: "rgba(228, 181, 70, 0.4)",
            "text-mask-from": "#ecca7a",
          },
          "second-brand": {
            500: "#961115",
            700: "#b41419",
          },
          "right-promotion-box": {
            to: "#e8bf60",
            from: "#ecca7a",
            border: "#e0ab2c",
            hover_to: "#e4b546",
            background: {
              platformBg: "#e3e3e3",
              transactionCard: "#fff",
            },
            hover_from: "#e8bf60",
          },
          "v3-second-brand": {
            100: "#e11a20",
            200: "#d2181d",
            300: "#c3161b",
            400: "#b41419",
            500: "#961115",
            600: "#870f13",
            700: "#780e11",
            800: "#710d10",
            900: "#690c0f",
            950: "#620b0e",
            1000: "#5a0a0d",
            "500-opacity": "#5a0a0d",
            "700-opacity": "#4b090b",
          },
          "v3-profile": {
            "banner-from": "#fbf3e2",
            "banner-to": "#f3dfae",
            "banner-border": "#d09b1e",
            "banner-title": "#efd494",
          },
          light: {
            100: "#f1f2f4",
            200: "#ffffff",
            300: "#DDE0E3",
            350: "rgb(170,170,170)",
            400: "#535353",
            500: "#15191C",
            600: "#5f5f5f",
          },
          dark: {
            100: "#0d0a02",
            150: "#1a1304",
            200: "#271d06",
            250: "#d09b1e",
            300: "#e0ab2c",
            400: "#e8bf60",
            500: "#e4b546",
            600: "#ecca7a",
            700: "#f3dfae",
          },
        },
      }
    : parseSomething(window.web_style_config);
